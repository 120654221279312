.br-6 {
  border-radius: 6px;
}

.card-container {
  border: 1px solid #CCC;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.notchedOutline {
  top: 0px
}

.notchedOutline legend {
  float: left;
}

legend {
  width: auto !important;
}

.list-pagination p {
  margin-bottom: 0px;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}

.headname {
  font-weight: bold;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  background: '#ECECEC';
  padding: 8px;
}

.cell-content {
  border-bottom: 1px solid #D8D8D8;
  padding: 8px;
}

.line-clamp-3 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}

.line-clamp-2 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}

.line-clamp-1 {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  word-wrap: break-word;
}

.table-list tbody tr:last-child td {
  border-bottom: none;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.br-1 {
  border-radius: 1px;
}
.br-2 {
  border-radius: 2px !important;
}
.br-3 {
  border-radius: 3px !important;
}
.br-4 {
  border-radius: 4px !important;
}
.br-5 {
  border-radius: 5px !important;
}
.br-6 {
  border-radius: 6px !important;
}
.br-7 {
  border-radius: 7px !important;
}
.br-8 {
  border-radius: 8px !important;
}
.br-9 {
  border-radius: 9px !important;
}
.br-10 {
  border-radius: 10px !important;
}
.br-12 {
  border-radius: 12px !important;
}
.br-round {
  border-radius: 50% !important;
}

.row-img {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column-img {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.column-img img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column-img {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column-img {
    flex: 100%;
    max-width: 100%;
  }
}

.ui.images {
  font-size: 0;
  margin: 0 -0.25rem;
}

.ui.small.image, .ui.small.images .image, .ui.small.images img, .ui.small.images svg {
  width: 150px;
  height: auto;
  font-size: .92857143rem;
}

.ui.images .image, .ui.images>img, .ui.images>svg {
  display: inline-block;
  margin: 0 0.25rem 0.5rem;
}

img.ui.image {
  display: block;
}

.ui.image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  background-color: transparent;
}

.form-upload-error {
  border: 2px dashed red !important;
}

.form-previous-btn {
  position: absolute;
  top: 50%;
  left: 20%;
  background-color: #F8F9FA;
  border-radius: 50%;
}

.form-next-btn {
  position: absolute;
  top: 50%;
  right: 20%;
  background-color: #F8F9FA;
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .form-previous-btn {
    left: 0%;
  }
  
  .form-next-btn {
    right: 0%;
  }
}

.ribbon {
  margin: 0;
  background: var(--bgRibbon, #128C46);
  color:var(--textRibbon, #FFF);
  padding: 10px 0px;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  z-index: 990;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: var(--bgRibbon, #128C46);;
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}

.custom-picker input {
  width: 100%;
  border-radius: 8px;
  padding: 16px 14px;
  border: 1px solid #C2C7CD;
  font-size: 16px;
}

.custom-picker input:focus {
  border: 2px solid #4CA75E;
  outline: none !important;
}

.custom-popper-picker .react-datepicker__triangle::after, .custom-popper-picker .react-datepicker__triangle::before {
  left: -20px !important;
}

.font-date {
  font-family: 'Public Sans', sans-serif;
  color: black;
  font-size: 1rem !important;
}

.text_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.wallet-card {
  width: 400px;
}

.button-topup {
  color: #FFF;
  border: 1px solid #FFF;
  transition: all 0.3s ease;
}

.button-topup:hover {
  background-color: #04AA6D;
  border: 1px solid #04AA6D;
  transition: all 0.3s ease;
}

.topup-icon {
  padding: 4px;
}

.topup-icon.active {
  /* border: 1px solid #CCC;
  border-radius: 50%; */
}

.topup-icon img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #ccc;
}

#cws_page_loader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  background: #fff;
  opacity: 0.9;
  z-index: 10000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cws_loader_container {
  display: flex !important;
}


#cws_page_loader .loadingspinner {
  pointer-events: none;
  width: 9em;
  height: 9em;
  border: 0.3em solid transparent;
  border-color: #ccc;
  border-top-color: #007b5c;
  border-radius: 50%;
  animation: loadingspin 0.6s linear infinite;
}

#cws_page_loader .lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes loadingspin {
  100% {
      transform: rotate(360deg)
  }
}

.product-list {

}

.dialog-title {
  font-size: 26px;
  padding: 30px 24px 10px 24px;
  text-align: center;
  color: #00AB55;
}

.dialog-actions {
  justify-content: center;
  padding-bottom: 30px !important;
}

.db-google_button{
  border-radius: 10px !important;
  width: 100% !important;
  place-content: center;
}

.select-team {
  width: 150px;
}

#show-noti {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.copy-btn {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #00AB55;
  color: #FFF;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.copy-btn:hover {
  background-color: #007b5c;
}

.flex-2 {
  flex: 2;
}

.flex-1 {
  flex: 1;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.list-pagination .MuiTablePagination-select {
  padding-right: 24px !important;
}

.table-list .row-active {
  background-color: rgba(0, 171, 85, 0.1);
}

.field-error {
  color: #FF4842;
  line-height: 1.5;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.tab-item {
  font-size: 16px;
  border-radius: 0;
  color: black !important;
}

.tab-item-active {
  font-size: 16px;
  border-radius: 0;
  color: green !important;
  border-bottom: 2px solid green;
  padding-bottom: 5px;
}

.font-14 {
  font-size: 14px;
}
